import { NavLink } from "react-router-dom";
import { Menu, X } from "lucide-react";
import { useState, useEffect } from "react";

const NavLinks = ({ isOpen, toggleNavbar }) => {
    const baseStyle = "text-xl my-2";
    const openStyle = isOpen ? "text-center" : "";
    return (
      <div className={`flex ${isOpen ? 'flex-col' : 'space-x-6'} items-center`}>
        <NavLink to='/' className= {`${baseStyle} ${openStyle} nav-link`} onClick={toggleNavbar}>Improve Your Text</NavLink>
        <NavLink to='/about' className={`${baseStyle} ${openStyle} nav-link`} onClick={toggleNavbar}>About SayItBetter</NavLink>
        {/* <NavLink to='/donations' className={`${baseStyle} ${openStyle}`} onClick={toggleNavbar}>Give</NavLink> */}
      </div>
    );
  };


  
const Nav = () => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        // const closeMenu = (event) => {
        //   if (event.target.closest('.nav-container')) return;
        //   setIsOpen(false);
        // };
    
        // document.addEventListener('click', closeMenu);
        // return () => document.removeEventListener('click', closeMenu);
      }, []);

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
        console.log("toggleNavbar " , isOpen);
    };

    return (
        <>
            <nav className="nav-container justify-end">
                <div className="hidden justify-between md:flex">
                    <NavLinks />
                </div>
                <div className=" md:hidden">
                <button onClick={toggleNavbar}>{isOpen ? <X /> : <Menu />}</button>
                </div>
            </nav>
            {isOpen && (
                <div className="flex flex-col items-center basis-full">
                    <NavLinks isOpen={isOpen} toggleNavbar={toggleNavbar} />
                </div>
            )}
        </>
    );
};

export default Nav;