import React from 'react'
import './Navbar.css'
import Nav from './Nav';

export const Navbar = () => {


  return (
    <div className='navbar sticky top-0 z-[20] mx-auto flex w-full items-center justify-around flex-wrap p-4 shadow-md'>
      <div className="nav-logo logo ">
      <p className='guidance'>Say <span className='it'>It</span>Better</p>
      </div>
      <Nav />
    </div>
  )
}

export default Navbar