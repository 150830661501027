import React from 'react'

export const Policy = () => {
  return (
    <div className="container about-container">
      <div className="flex-container">
        <div className="header-container">
          <h1 className="section-title">Privacy Policy for sayItBetter</h1>
        </div>
        <div className="text-content">
          <p className="section-content">Effective Date: 1.1.2024</p>
          <p className="section-content">
            Welcome to sayItBetter (www.sayItBetter.online). We value the privacy and security of our visitors' information and are committed to protecting it. This Privacy Policy outlines the types of information we collect, how it's used, and the measures we take to protect it.
          </p>
          <h2 className="section-title">Collection of Information</h2>
          <p className="section-content">
            We collect information in the following ways:
            <ul>
              <li>Information you provide directly: This includes any information you submit through our contact forms, donation processes, or any other interaction where you voluntarily provide information.</li>
              <li>Automated information collection: We automatically collect certain information when you visit our website, such as your IP address, browser type, and access times, through the use of cookies and similar technologies.</li>
            </ul>
          </p>
          <h2 className="section-title">Use of Information</h2>
          <p className="section-content">
            The information we collect is used to:
            <ul>
              <li>Process donations and confirm transactions.</li>
              <li>Improve our website and tailor user experiences.</li>
              <li>Communicate with you about your donations, questions, or any other inquiries.</li>
              <li>Ensure compliance with legal obligations and enforce our terms and policies.</li>
            </ul>
          </p>
          <h2 className="section-title">Data Protection</h2>
          <p className="section-content">
            We implement a variety of security measures to maintain the safety of your personal information. However, no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.
          </p>
          <h2 className="section-title">Your Rights</h2>
          <p className="section-content">
            You have the right to:
            <ul>
              <li>Access the personal information we hold about you.</li>
              <li>Request the correction of incorrect or incomplete information.</li>
              <li>Request the deletion of your personal information.</li>
              <li>Object to the processing of your personal information.</li>
              <li>Request the restriction of processing your personal information.</li>
            </ul>
          </p>
          <h2 className="section-title">Cookies Policy</h2>
          <p className="section-content">
            Our website uses cookies to enhance user experience, analyze site usage, and tailor the ads presented. You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer.
          </p>
          <h2 className="section-title">Changes to This Privacy Policy</h2>
          <p className="section-content">
            We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. We encourage you to review this Privacy Policy periodically for any changes.
          </p>
          <h2 className="section-title">Contact Us</h2>
          <p className="section-content">
            If you have any questions about this Privacy Policy, please contact us through the preferred contact method mentioned on our website.
          </p>
        </div>
      </div>
    </div>
  )
}
