import React from 'react'
import './About.css'

export const About = () => {
    return (
        <div className="container about-container">
            <div className="flex-container">
                <div className="header-container">
                    <h1 className="section-title">About SayItBetter</h1>
                </div>
                <div className="text-content">
                    <p className="section-content">
                        <strong>Disclaimer:</strong> SayItBetter.online is dedicated to improving written communication and should not be considered a substitute for professional writing or editing services.
                    </p>
                    <h2 className="section-title">Our Mission</h2>
                    <p className="section-content">
                        Our mission at SayItBetter.online is to help users communicate more effectively by providing tools that enhance the clarity, correctness, and professionalism of their written text.
                    </p>
                    <h2 className="section-title">How It Works</h2>
                    <div className="section-content">
                        <p>
                            Users can simply input their text into the provided text box and click the "Say It Better" button to receive improved and grammatically correct versions of their text.
                        </p>
                    </div>
                    <h2 className="section-title">Beta Version</h2>
                    <p className="section-content">
                        SayItBetter.online is currently in beta, and we are continuously working to enhance its features and functionality. Stay tuned for updates and new improvements!
                    </p>
                </div>
            </div>
        </div>
    );
};
