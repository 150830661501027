import './App.css';
import { Navbar } from './Components/Navbar/Navbar';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Home } from './Pages/Home';
import { About } from './Components/About/About';
import { FAQ } from './Pages/FAQ';
import { Contact } from './Pages/Contact';
import { Subscribe } from './Pages/Subscribe';
import { LoginSignup } from './Pages/LoginSignup';
import { Question } from './Components/Question/Question';
import { Answer } from './Components/Answer/Answer';
import { Policy } from './Components/Legal/Policy';
import { Footer } from './Components/Footer/Footer';
import { Terms } from './Components/Legal/Terms';
import { Refund } from './Components/Legal/Refund';
import { DonationsPage } from './Pages/DonationsPage';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Navbar/>
        <Routes>
          <Route path='/' element={<Home/>}></Route>
          <Route path='/about' element={<About/>}></Route>
          <Route path='/faq' element={<FAQ/>}></Route>
          <Route path='/contact' element={<Contact/>}></Route>
          <Route path='/subscribe' element={<Subscribe/>}></Route>
          <Route path="login" element={<LoginSignup/>}></Route>
          <Route path="/" exact element={<Question/>}></Route>
          <Route path="/answer" exact element={<Answer/>}></Route>
          <Route path="/policy" exact element={<Policy/>}></Route>
          <Route path="/terms" exact element={<Terms/>}></Route>
          <Route path="/donations" exact element={<DonationsPage/>}></Route>
          <Route path="/refund" exact element={<Refund/>}></Route>
        </Routes>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
