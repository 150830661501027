import React from 'react'
// import { Hero } from '../Components/Hero/Hero'
import { Question } from '../Components/Question/Question'

export const Home = () => {
  return (
    <div>
      {/* <Hero/> */}
      <Question/>
    </div>
  )
}
