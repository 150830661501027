import React from 'react';
import { Link } from 'react-router-dom'; // This line is assuming you're using React Router for navigation

export const DonationsPage = () => {
  return (
    <div className="container about-container">
      <div className="flex-container">
        <div className="header-container">
          <h1 className="section-title">Support sayItBetter</h1>
        </div>
        <div className="text-content">
          <p className="section-content">
            sayItBetter is a labor of love, freely available to all who seek to explore and understand the Bible in a new and deeper way. We are not a charity, but a private initiative funded from our own personal finances. 
          </p>
          <p className="section-content">
            Running and maintaining sayItBetter involves various costs, including hosting, development, and content creation. If you find value in what we do and would like to support our mission, we welcome your donations with gratitude. Your support helps us to continue improving and expanding sayItBetter for everyone.
          </p>
          <p className="section-content">
            Please note that donations to sayItBetter are not tax-deductible. We are committed to transparency and integrity in every aspect of our work, including how we use the funds we receive.
          </p>
          <br />
          <div className="button-container">
            <Link to="/refund" className="search-button">View Refund Policy</Link> {/* Adjust the link to match your route */}
          </div>
        </div>
      </div>
    </div>
  )
}