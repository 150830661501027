import React from 'react';

export const Refund = () => {
  return (
    <div className="container about-container">
      <div className="flex-container">
        <div className="header-container">
          <h1 className="section-title">Refund Policy for Donations</h1>
        </div>
        <div className="text-content">
          <p className="section-content">Effective Date: 2.27.2024</p>
          <p className="section-content">
            At sayItBetter, we are grateful for the generosity of our donors and their willingness to support our mission. We understand that circumstances can change and that you may need to request a refund for your donation. This Refund Policy outlines the conditions under which refunds can be made.
          </p>
          <h2 className="section-title">Eligibility for Refund</h2>
          <p className="section-content">
            Refunds for donations made to sayItBetter are considered on a case-by-case basis and are granted in accordance with the following conditions:
            <ul>
              <li>Donations made in error: If you have made a donation in error, please contact us within 14 days of the donation date.</li>
              <li>Duplicate transactions: If your donation was processed more than once by mistake, please contact us within 14 days of the donation date for a refund of the duplicate transaction(s).</li>
            </ul>
          </p>
          <h2 className="section-title">Requesting a Refund</h2>
          <p className="section-content">
            To request a refund, please contact us with the details of your donation, including:
            <ul>
              <li>The date of the donation.</li>
              <li>The amount donated.</li>
              <li>The name and email address used to make the donation.</li>
              <li>A brief explanation of the reason for the refund request.</li>
            </ul>
            Our team will review your request and respond within 7 business days.
          </p>
          <h2 className="section-title">Processing Refunds</h2>
          <p className="section-content">
            Approved refunds will be processed using the original method of payment. Please note that it may take up to 10 business days for the refund to appear on your statement.
          </p>
          <h2 className="section-title">Non-refundable Donations</h2>
          <p className="section-content">
            Please note that donations used for specific projects or where benefits have been received by the donor are generally non-refundable. However, we recognize that exceptional circumstances may occur, and we invite you to discuss these with us.
          </p>
          <h2 className="section-title">Changes to This Refund Policy</h2>
          <p className="section-content">
            We may update our Refund Policy from time to time. We will notify you of any changes by posting the new Refund Policy on this page. We encourage you to review our Refund Policy periodically for any changes.
          </p>
          <h2 className="section-title">Contact Us</h2>
          <p className="section-content">
            If you have any questions about this Refund Policy or if you wish to request a refund, please contact us through the preferred contact method mentioned on our website.
          </p>
          <br></br>
          {/* <p>contact us via mail: milosavljevic.nikolas023@gmail.com</p> */}
        </div>
      </div>
    </div>
  )
}