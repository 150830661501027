export const Footer = () => {
  return (
    <footer
    className="bg-neutral-100 text-center dark:bg-neutral-600 lg:text-left">
    <div className="container p-6">
      <div className="grid md:grid-cols-2 lg:grid-cols-2">

        <div className="mb-6">
        <h5
            className="mb-2.5 font-bold uppercase text-neutral-800 dark:text-neutral-200">
            Pages
          </h5>

          <ul className="mb-0 list-none">
            <li>
              <a className="text-neutral-800 dark:text-neutral-200" href="/">Home</a>
            </li>
            <li>
              <a className="text-neutral-800 dark:text-neutral-200" href="/about">About Us</a>
            </li>
          </ul>
        </div>

        <div className="mb-4">
          <h5
            className="mb-2.5 font-bold uppercase text-neutral-800 dark:text-neutral-200">
            Legal
          </h5>

          <ul className="mb-0 list-none">
            <li>
              <a className="text-neutral-800 dark:text-neutral-200" href="/policy">Privacy Policy</a>
            </li>
            <li>
              <a className="text-neutral-800 dark:text-neutral-200" href="/terms">Terns of Use</a>
            </li>
            <li>
              <a className="text-neutral-800 dark:text-neutral-200" href="/refund">Refund Policy</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    {/* <!--Copyright section--> */}
    <div
      className="bg-neutral-200 p-4 text-center text-neutral-700 dark:bg-neutral-700 dark:text-neutral-200">
      © 2024 Copyright:
      <a
        className="text-neutral-800 dark:text-neutral-400"
        href="http://milosavljevicnikola.tech/"
      >Nikola Milosavljevic</a>
    </div>
  </footer>
  );
}